var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "formData",
      staticClass: "demo-form",
      attrs: {
        model: _vm.formData,
        "status-icon": "",
        rules: _vm.rules,
        "label-position": "left"
      }
    },
    [
      _c(
        "div",
        { staticClass: "variation-attribute-container" },
        [
          _vm._l(_vm.attributesAndValues, function(attributesAndValue, index) {
            return _c(
              "div",
              {
                key: attributesAndValue.id,
                staticClass: "variation-attribute"
              },
              [
                _c(
                  "el-select",
                  {
                    attrs: {
                      placeholder: "Select " + attributesAndValue.name,
                      value: attributesAndValue.selectedValueId
                    },
                    on: {
                      change: function($event) {
                        return _vm.handleAttribute(
                          $event,
                          attributesAndValue.id,
                          index
                        )
                      }
                    }
                  },
                  _vm._l(attributesAndValue.attributesValues, function(
                    attributesValue
                  ) {
                    return _c("el-option", {
                      key: attributesValue.id,
                      attrs: {
                        label: attributesValue.name,
                        value: attributesValue.id
                      }
                    })
                  }),
                  1
                )
              ],
              1
            )
          }),
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                icon: "el-icon-circle-plus"
              },
              on: {
                click: function($event) {
                  return _vm.addVariation()
                }
              }
            },
            [_vm._v(" Add ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", icon: "el-icon-delete" },
              on: {
                click: function($event) {
                  _vm.showDelete = !_vm.showDelete
                }
              }
            },
            [_vm._v(" Delete ")]
          )
        ],
        2
      ),
      _c("br"),
      _vm.productsVariations.length
        ? _c(
            "el-collapse",
            { attrs: { accordion: "" } },
            _vm._l(_vm.productsVariations, function(variation, index) {
              return _c(
                "el-collapse-item",
                { key: index, attrs: { name: index } },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _vm.showDelete
                        ? _c("el-button", {
                            staticClass: "danger",
                            attrs: {
                              size: "mini",
                              circle: "",
                              type: "text",
                              icon: "el-icon-delete"
                            },
                            on: {
                              click: function($event) {
                                return _vm.handleDelete($event, index)
                              }
                            }
                          })
                        : _vm._e(),
                      !variation.edit
                        ? [
                            _vm._v(
                              " " +
                                _vm._s(
                                  (variation.id
                                    ? "#" + variation.id + ": "
                                    : "") + variation.name
                                ) +
                                " "
                            ),
                            _c("el-button", {
                              attrs: { type: "text", icon: "el-icon-edit" },
                              on: {
                                click: function($event) {
                                  return _vm.editName($event, index)
                                }
                              }
                            })
                          ]
                        : _c(
                            "el-input",
                            {
                              staticStyle: { width: "300px" },
                              attrs: {
                                size: "mini",
                                placeholder: "enter name"
                              },
                              model: {
                                value: variation.name,
                                callback: function($$v) {
                                  _vm.$set(variation, "name", $$v)
                                },
                                expression: "variation.name"
                              }
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-check"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.saveName($event, index)
                                  }
                                },
                                slot: "append"
                              })
                            ],
                            1
                          )
                    ],
                    2
                  ),
                  _c(
                    "div",
                    { staticClass: "variation-container" },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("div", { staticClass: "sub-title" }, [
                                  _vm._v("Enabled")
                                ]),
                                _c("el-switch", {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: variation.enabled,
                                    callback: function($$v) {
                                      _vm.$set(variation, "enabled", $$v)
                                    },
                                    expression: "variation.enabled"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("div", { staticClass: "sub-title" }, [
                                  _vm._v("Featured")
                                ]),
                                _c("el-switch", {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: variation.featured,
                                    callback: function($$v) {
                                      _vm.$set(variation, "featured", $$v)
                                    },
                                    expression: "variation.featured"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("div", { staticClass: "sub-title" }, [
                                _vm._v("Regular Price")
                              ]),
                              _c("el-input-number", {
                                attrs: {
                                  name: "unitPrice",
                                  placeholder: "Regular rice",
                                  size: "mini",
                                  min: 0,
                                  precision: 2
                                },
                                model: {
                                  value: variation.unitPrice,
                                  callback: function($$v) {
                                    _vm.$set(variation, "unitPrice", $$v)
                                  },
                                  expression: "variation.unitPrice"
                                }
                              }),
                              _c("help-text", { attrs: { content: "" } })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("div", { staticClass: "sub-title" }, [
                                _vm._v("Sale Price")
                              ]),
                              _c("el-input-number", {
                                attrs: {
                                  name: "salePrice",
                                  placeholder: "Sale rice",
                                  size: "mini",
                                  min: 0,
                                  max: variation.unitPrice * 1,
                                  precision: 2
                                },
                                model: {
                                  value: variation.salePrice,
                                  callback: function($$v) {
                                    _vm.$set(variation, "salePrice", $$v)
                                  },
                                  expression: "variation.salePrice"
                                }
                              }),
                              _c("help-text", { attrs: { content: "" } })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("div", { staticClass: "sub-title" }, [
                                _vm._v("SKU")
                              ]),
                              _c("el-input", {
                                staticClass: "half-width",
                                attrs: {
                                  name: "sku",
                                  placeholder: "SKU",
                                  size: "mini"
                                },
                                model: {
                                  value: variation.sku,
                                  callback: function($$v) {
                                    _vm.$set(variation, "sku", $$v)
                                  },
                                  expression: "variation.sku"
                                }
                              }),
                              _c("help-text", {
                                attrs: {
                                  content:
                                    "SKU refers to a Stock Keeping unit, a unique identifier for each distinct product"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c("div", { staticClass: "sub-title" }, [
                                _vm._v("Short Description")
                              ]),
                              _c("el-input", {
                                staticClass: "half-width",
                                attrs: {
                                  type: "textarea",
                                  name: "shortDescription",
                                  size: "mini"
                                },
                                model: {
                                  value: variation.shortDescription,
                                  callback: function($$v) {
                                    _vm.$set(variation, "shortDescription", $$v)
                                  },
                                  expression: "variation.shortDescription"
                                }
                              }),
                              _c("help-text", {
                                attrs: {
                                  content:
                                    "add one line description for product"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: variation.usersRolesPrice,
                                    border: "",
                                    fit: "",
                                    "highlight-current-row": ""
                                  }
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { align: "center", label: "Role" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.usersRolesList[
                                                      scope.row.usersRoleId
                                                    ]
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "Visibility",
                                      prop: "showProduct"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("el-switch", {
                                                attrs: { name: "showProduct" },
                                                model: {
                                                  value: scope.row.showProduct,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "showProduct",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.showProduct"
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "Percentage",
                                      prop: "percentage"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("el-input-number", {
                                                attrs: {
                                                  name: "percentage",
                                                  size: "mini",
                                                  min: 0,
                                                  max: 100,
                                                  "controls-position": "right",
                                                  precision: 2
                                                },
                                                model: {
                                                  value: scope.row.percentage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "percentage",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.percentage"
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      label: "Unit Price"
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    (
                                                      variation.unitPrice *
                                                      (scope.row.percentage *
                                                        0.01)
                                                    ).toFixed(2)
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  }),
                                  variation.salePrice > 0
                                    ? _c("el-table-column", {
                                        attrs: {
                                          align: "center",
                                          label: "Sale Price"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(scope) {
                                                return [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        (
                                                          variation.salePrice *
                                                          (scope.row
                                                            .percentage *
                                                            0.01)
                                                        ).toFixed(2)
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content bg-purple" },
                              [
                                _c("div", { staticClass: "sub-title" }, [
                                  _vm._v("Manage Stock")
                                ]),
                                _c("el-switch", {
                                  attrs: { size: "mini" },
                                  model: {
                                    value: variation.manageStock,
                                    callback: function($$v) {
                                      _vm.$set(variation, "manageStock", $$v)
                                    },
                                    expression: "variation.manageStock"
                                  }
                                }),
                                _c("help-text", {
                                  attrs: {
                                    content:
                                      "Enable stock management at product level"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          !variation.manageStock
                            ? _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  { staticClass: "grid-content bg-purple" },
                                  [
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v("Stock Status")
                                    ]),
                                    _c("el-switch", {
                                      attrs: {
                                        size: "mini",
                                        "active-text": "In Stock",
                                        "inactive-text": "Out Of Stock",
                                        "active-value": "In Stock",
                                        "inactive-value": "Out Of Stock"
                                      },
                                      model: {
                                        value: variation.stockStatus,
                                        callback: function($$v) {
                                          _vm.$set(
                                            variation,
                                            "stockStatus",
                                            $$v
                                          )
                                        },
                                        expression: "variation.stockStatus"
                                      }
                                    }),
                                    _c("help-text", {
                                      attrs: {
                                        content:
                                          "Enable stock management at product level"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 20 } },
                        [
                          variation.manageStock
                            ? _c(
                                "el-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("div", { staticClass: "sub-title" }, [
                                    _vm._v("Stock Quantity")
                                  ]),
                                  _c("el-input-number", {
                                    attrs: {
                                      name: "stockQuantity",
                                      placeholder: "Stock Quantity",
                                      size: "mini",
                                      min: 0,
                                      "controls-position": "right",
                                      precision: 0
                                    },
                                    model: {
                                      value: variation.stockQuantity,
                                      callback: function($$v) {
                                        _vm.$set(
                                          variation,
                                          "stockQuantity",
                                          $$v
                                        )
                                      },
                                      expression: "variation.stockQuantity"
                                    }
                                  }),
                                  _c("help-text", { attrs: { content: "" } })
                                ],
                                1
                              )
                            : _vm._e(),
                          variation.manageStock
                            ? _c("el-col", { attrs: { span: 12 } }, [
                                _c(
                                  "div",
                                  { staticClass: "grid-content bg-purple" },
                                  [
                                    _c("div", { staticClass: "sub-title" }, [
                                      _vm._v("Allow backorders")
                                    ]),
                                    _c("el-switch", {
                                      attrs: { size: "mini" },
                                      model: {
                                        value: variation.allowBackOrder,
                                        callback: function($$v) {
                                          _vm.$set(
                                            variation,
                                            "allowBackOrder",
                                            $$v
                                          )
                                        },
                                        expression: "variation.allowBackOrder"
                                      }
                                    }),
                                    _c("help-text", {
                                      attrs: {
                                        content:
                                          "If managing stock, this controls whether or not backorders are allowed. If enabled, stock quantity can go below 0"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("el-row", { attrs: { gutter: 20 } })
                    ],
                    1
                  )
                ],
                2
              )
            }),
            1
          )
        : _vm._e(),
      _c("br"),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                },
                { name: "waves", rawName: "v-waves" }
              ],
              attrs: { type: "success" },
              on: { click: _vm.submitForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }