var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "formData",
      staticClass: "demo-form",
      attrs: {
        model: _vm.formData,
        "status-icon": "",
        rules: _vm.rules,
        "label-position": "left"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Enabled", prop: "enabled" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.formData.enabled,
              callback: function($$v) {
                _vm.$set(_vm.formData, "enabled", $$v)
              },
              expression: "formData.enabled"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Name", prop: "name" } },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              name: "name",
              required: "",
              placeholder: "Name",
              maxlength: "50",
              minlength: "3",
              "show-word-limit": ""
            },
            model: {
              value: _vm.formData.name,
              callback: function($$v) {
                _vm.$set(_vm.formData, "name", $$v)
              },
              expression: "formData.name"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          attrs: { label: "Unit of Measurement", prop: "unitOfMeasurementId" }
        },
        [
          _c(
            "el-select",
            {
              attrs: {
                placeholder: "Select",
                name: "unitOfMeasurementId",
                required: ""
              },
              model: {
                value: _vm.formData.unitOfMeasurementId,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "unitOfMeasurementId", $$v)
                },
                expression: "formData.unitOfMeasurementId"
              }
            },
            _vm._l(_vm.unitOfMeasurements, function(unit) {
              return _c("el-option", {
                key: unit.id,
                attrs: {
                  label: unit.name + " (" + unit.code + ")",
                  value: unit.id
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm.formData.productType === "simple" &&
      _vm.formData.productsVariation.length
        ? [
            _c(
              "el-form-item",
              { attrs: { label: "Price" } },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 11 } },
                      [
                        _c("div", { staticClass: "sub-title" }, [
                          _vm._v(" Regular Price ")
                        ]),
                        _c("el-input-number", {
                          attrs: {
                            name: "unitPrice",
                            placeholder: "Regular rice",
                            size: "mini",
                            min: 0,
                            precision: 2
                          },
                          model: {
                            value: _vm.formData.productsVariation[0].unitPrice,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formData.productsVariation[0],
                                "unitPrice",
                                $$v
                              )
                            },
                            expression:
                              "formData.productsVariation[0].unitPrice"
                          }
                        }),
                        _c("help-text", { attrs: { content: "" } })
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 11 } },
                      [
                        _c("div", { staticClass: "sub-title" }, [
                          _vm._v(" Sale Price ")
                        ]),
                        _c("el-input-number", {
                          attrs: {
                            name: "salePrice",
                            placeholder: "Sale rice",
                            size: "mini",
                            min: 0,
                            max: _vm.formData.productsVariation[0].unitPrice,
                            precision: 2
                          },
                          model: {
                            value: _vm.formData.productsVariation[0].salePrice,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.formData.productsVariation[0],
                                "salePrice",
                                $$v
                              )
                            },
                            expression:
                              "formData.productsVariation[0].salePrice"
                          }
                        }),
                        _c("help-text", { attrs: { content: "" } })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              data:
                                _vm.formData.productsVariation[0]
                                  .usersRolesPrice,
                              border: "",
                              fit: "",
                              "highlight-current-row": ""
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "Role",
                                prop: "usersRoleId"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.usersRolesList[
                                                scope.row.usersRoleId
                                              ]
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3740846663
                              )
                            }),
                            _c("el-table-column", {
                              attrs: {
                                align: "center",
                                label: "Percentage",
                                prop: "percentage"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _c("el-input-number", {
                                          attrs: {
                                            name: "percentage",
                                            size: "mini",
                                            min: 0,
                                            max: 100,
                                            "controls-position": "right",
                                            precision: 2
                                          },
                                          model: {
                                            value: scope.row.percentage,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "percentage",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.percentage"
                                          }
                                        })
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                634575329
                              )
                            }),
                            _c("el-table-column", {
                              attrs: { align: "center", label: "Unit Price" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formData.productsVariation[0]
                                                .unitPrice *
                                                (scope.row.percentage * 0.01)
                                            ) +
                                            " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                149640169
                              )
                            }),
                            _vm.formData.productsVariation[0].salePrice > 0
                              ? _c("el-table-column", {
                                  attrs: {
                                    align: "center",
                                    label: "Sale Price"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(scope) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.formData
                                                    .productsVariation[0]
                                                    .salePrice *
                                                    (scope.row.percentage *
                                                      0.01)
                                                ) +
                                                " "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2799812020
                                  )
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "SKU" } },
              [
                _c("el-input", {
                  attrs: {
                    name: "sku",
                    placeholder: "SKU",
                    maxlength: "30",
                    minlength: "3"
                  },
                  model: {
                    value: _vm.formData.productsVariation[0].sku,
                    callback: function($$v) {
                      _vm.$set(_vm.formData.productsVariation[0], "sku", $$v)
                    },
                    expression: "formData.productsVariation[0].sku"
                  }
                })
              ],
              1
            )
          ]
        : _vm._e(),
      _c(
        "el-form-item",
        { attrs: { label: "Featured", prop: "featured" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.formData.featured,
              callback: function($$v) {
                _vm.$set(_vm.formData, "featured", $$v)
              },
              expression: "formData.featured"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Bazar Dhara", prop: "allowBazarDhara" } },
        [
          _c("el-switch", {
            model: {
              value: _vm.formData.allowBazarDhara,
              callback: function($$v) {
                _vm.$set(_vm.formData, "allowBazarDhara", $$v)
              },
              expression: "formData.allowBazarDhara"
            }
          })
        ],
        1
      ),
      _vm.formData.allowBazarDhara
        ? _c(
            "el-form-item",
            {
              attrs: {
                label: "Bazar Dhara Discount %",
                prop: "bazarDharaDiscount"
              }
            },
            [
              _c("el-input-number", {
                attrs: {
                  name: "bazarDharaDiscount",
                  min: 0,
                  max: 100,
                  precision: 2
                },
                model: {
                  value: _vm.formData.bazarDharaDiscount,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "bazarDharaDiscount", $$v)
                  },
                  expression: "formData.bazarDharaDiscount"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        {
          staticClass: "half-width",
          attrs: { label: "Supplied By", prop: "suppliedBy" }
        },
        [
          _c("el-input", {
            attrs: {
              type: "text",
              name: "suppliedBy",
              placeholder: "Supplied By"
            },
            model: {
              value: _vm.formData.suppliedBy,
              callback: function($$v) {
                _vm.$set(_vm.formData, "suppliedBy", $$v)
              },
              expression: "formData.suppliedBy"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Taxable", prop: "isTaxable" } },
        [
          _c("el-switch", {
            attrs: {
              "inactive-text": "Tax is inclusive",
              "active-text": "Taxes extra"
            },
            model: {
              value: _vm.formData.isTaxable,
              callback: function($$v) {
                _vm.$set(_vm.formData, "isTaxable", $$v)
              },
              expression: "formData.isTaxable"
            }
          })
        ],
        1
      ),
      _vm.formData.isTaxable
        ? _c(
            "el-form-item",
            { attrs: { label: "Tax", prop: "taxId" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Select", name: "taxId", required: "" },
                  model: {
                    value: _vm.formData.taxId,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "taxId", $$v)
                    },
                    expression: "formData.taxId"
                  }
                },
                _vm._l(_vm.taxes, function(tax) {
                  return _c("el-option", {
                    key: tax.id,
                    attrs: { label: tax.name, value: tax.id }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-form-item",
        {
          staticClass: "half-width",
          attrs: { label: "Hsn Code", prop: "hsnCode" }
        },
        [
          _c("el-input", {
            attrs: { type: "number", name: "hsnCode", placeholder: "Hsn Code" },
            model: {
              value: _vm.formData.hsnCode,
              callback: function($$v) {
                _vm.$set(_vm.formData, "hsnCode", $$v)
              },
              expression: "formData.hsnCode"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        {
          staticClass: "half-width",
          attrs: { label: "Youtube Link", prop: "videoUrl" }
        },
        [
          _c("el-input", {
            attrs: { name: "videoUrl", placeholder: "enter youtube url" },
            model: {
              value: _vm.formData.videoUrl,
              callback: function($$v) {
                _vm.$set(_vm.formData, "videoUrl", $$v)
              },
              expression: "formData.videoUrl"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              attrs: { loading: _vm.loading, type: "success" },
              on: { click: _vm.submitForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }