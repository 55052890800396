var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "formData",
      staticClass: "demo-form",
      attrs: {
        model: _vm.formData,
        "status-icon": "",
        rules: _vm.rules,
        "label-position": "left"
      }
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "Bid Allowed %", prop: "bidAllowed" } },
        [
          _c("el-input-number", {
            attrs: {
              name: "bidAllowed",
              placeholder: "Bid Allowed %",
              min: 0,
              max: 100,
              precision: 2
            },
            model: {
              value: _vm.formData.bidAllowed,
              callback: function($$v) {
                _vm.$set(_vm.formData, "bidAllowed", $$v)
              },
              expression: "formData.bidAllowed"
            }
          }),
          _c("help-text", {
            attrs: {
              content:
                "Percentage of product amount to be considered while placing a bid"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Priority", prop: "priority" } },
        [
          _c("el-input-number", {
            attrs: { name: "priority", min: 0, max: 100 },
            model: {
              value: _vm.formData.priority,
              callback: function($$v) {
                _vm.$set(_vm.formData, "priority", $$v)
              },
              expression: "formData.priority"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Reward Amount", prop: "rewardAmount" } },
        [
          _c("el-input-number", {
            attrs: { name: "rewardAmount", min: 0, precision: 2 },
            model: {
              value: _vm.formData.rewardAmount,
              callback: function($$v) {
                _vm.$set(_vm.formData, "rewardAmount", $$v)
              },
              expression: "formData.rewardAmount"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Description", prop: "description" } },
        [
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 2, maxRows: 10 },
              name: "description",
              placeholder: "Description",
              maxlength: "1000",
              "show-word-limit": ""
            },
            model: {
              value: _vm.formData.description,
              callback: function($$v) {
                _vm.$set(_vm.formData, "description", $$v)
              },
              expression: "formData.description"
            }
          })
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "Tags", prop: "tags" } },
        [
          _vm._l(
            _vm.formData.tags ? _vm.formData.tags.split(",") : [],
            function(tag) {
              return _c(
                "el-tag",
                {
                  key: tag,
                  attrs: { closable: "", "disable-transitions": false },
                  on: {
                    close: function($event) {
                      return _vm.handleTagClose(tag)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(tag) + " ")]
              )
            }
          ),
          _vm.inputTagVisible
            ? _c("el-input", {
                ref: "saveTagInput",
                staticClass: "input-new-tag",
                attrs: { size: "mini" },
                on: { blur: _vm.handleTagInputConfirm },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleTagInputConfirm($event)
                  }
                },
                model: {
                  value: _vm.inputTagValue,
                  callback: function($$v) {
                    _vm.inputTagValue = $$v
                  },
                  expression: "inputTagValue"
                }
              })
            : _c(
                "el-button",
                {
                  staticClass: "button-new-tag",
                  attrs: { size: "small" },
                  on: { click: _vm.showTagInput }
                },
                [_vm._v(" + New Tag ")]
              )
        ],
        2
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              attrs: { loading: _vm.loading, type: "success" },
              on: { click: _vm.submitForm }
            },
            [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }